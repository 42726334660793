import React from "react";
import PropTypes from "prop-types";

import { Circle, Group, Text, Wedge } from "react-konva";

import { circleProps, textProps, wedgeProps } from "./ShapeProps";
import { Battery, levels } from "./Battery";
import { GOR, neutral } from "../colors";
import { useCyclicState } from "../../hooks";

export default function Target({
  value,
  interactive,
  label,
  nr,
  onChange,
  selection,
  setSelection,
  ...props
}) {
  const [level, forwardLevel] = useCyclicState(levels, value);
  var shape, groupProps;

  if (interactive) {
    const forward = () => onChange(forwardLevel());
    groupProps = {
      onClick: forward,
      onTouchStart: forward
    };

    textProps.y = -20;
    shape = (
      <>
        <Circle {...circleProps} fill={neutral} />
        <Battery level={level} />
      </>
    );
  } else {
    const values = Object.keys(value);
    const size = 360 / values.reduce((sum, col) => sum + value[col], 0);
    // build color index
    const palette = levels.reduce((obj, lev, nr) => {
      obj[lev] = nr > 0 ? GOR[nr - 1] : neutral;
      return obj;
    }, {});

    var rotation = 0;
    shape = levels
      .filter(l => value[l])
      .map((l, nr) => {
        const angle = size * value[l];
        const wedge = { fill: palette[l], rotation, angle };
        rotation += angle;
        return <Wedge key={nr} {...wedgeProps} {...wedge} />;
      });
  }

  return (
    <Group {...props} {...groupProps}>
      {shape}
      <Text {...textProps} fill="white" text={label} />
    </Group>
  );
}

Target.propTypes = {
  label: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
