import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Button, Icon, Tab, Tabs } from "@blueprintjs/core";

import Circle from "./Circle";
import Graph from "./Graph";
import Setting from "./Setting";

// =========================================

function Question(props) {
  var {
    answers,
    title,
    type,
    graph,
    members,
    others,
    confidential,
    translate,
    meta
  } = props;
  const [hidden, setHidden] = React.useState(true);
  const first = graph && graph.type === "none";

  if (type === "html") {
    return (
      <tr>
        <td
          colSpan={2}
          style={{ paddingTop: "30pt" }}
          dangerouslySetInnerHTML={{ __html: props.html }}
        />
      </tr>
    );
  }

  function toggleHidden() {
    if (hidden) {
      if (window.confirm(translate("CONFIDENTIAL CONFIRM"))) {
        setHidden(false);
      }
    } else {
      setHidden(true);
    }
  }

  var answer = undefined;
  if (graph) {
    answer = (
      <Graph
        question={props}
        members={members}
        others={others}
        translate={props.translate}
      />
    );
  } else {
    switch (type) {
      case "setting":
        answer = <Setting {...props} translate={props.translate} />;
        break;
      case "circle":
        answer = <Circle {...props} translate={props.translate} />;
        break;
      case "text":
        if (confidential === undefined) {
          confidential = true;
        }
        answer = (
          <ul>
            {answers &&
              answers.map((answer, nr) => <li key={nr}>{answer.value}</li>)}
          </ul>
        );
        break;
      default:
        answer = "Onbekend type";
    }
  }

  if (meta) {
    meta = meta.reduce((obj, m) => {
      if (obj[m.meta]) {
        obj[m.meta] += 1;
      } else {
        obj[m.meta] = 1;
      }
      return obj;
    }, {});
    answer = (
      <>
        {answer}
        <p>
          {Object.keys(meta)
            .map(key => `${key}: ${meta[key]}`)
            .sort()
            .join(", ")}
        </p>
      </>
    );
  }

  if (confidential) {
    answer =
      answers === undefined ? (
        translate("CONFIDENTIAL")
      ) : (
        <div style={{ minWidth: "300pt" }}>
          <Button onClick={toggleHidden}>
            {translate("CONFIDENTIAL ANSWERS")}
          </Button>
          {hidden || answer}
        </div>
      );
  }

  // css from survey component
  return ["setting", "circle"].includes(type) ? (
    <>
      <tr style={{ pageBreakInside: "avoid" }}>
        <td className="question" colSpan={2}>
          {title}
        </td>
      </tr>
      <tr className="report" style={{ pageBreakInside: "avoid" }}>
        <td colSpan={2}>{answer}</td>
      </tr>
    </>
  ) : (
    <tr className="report" style={{ pageBreakInside: "avoid" }}>
      <td
        className="question"
        style={first ? { borderBottomColor: "white" } : {}}
      >
        {title}
      </td>
      <td style={first ? { borderBottomColor: "white" } : {}}>{answer}</td>
    </tr>
  );
}

// =========================================

function Page({ title, description, questions, members, translate }) {
  // set default graphs
  questions.forEach(question => {
    const { graph } = question;
    if (graph) {
      if (graph.type === "scatter") {
        // avoid two graphs for one question
        const others = [...graph.y];
        if (graph.x) {
          others.push(graph.x);
        }
        others
          .filter(name => name !== question.name)
          .forEach(name => {
            const other = questions.find(q => q.name === name);
            if (other) {
              other.graph = { type: "none" };
            }
          });
      }
    } else {
      if (question.type === "number") {
        question.graph = { type: "histogram" };
      } else if (question.type === "select") {
        question.graph = { type: "bar" };
      }
    }
  });

  return (
    <div style={{ pageBreakBefore: "always" }}>
      <h4>{title}</h4>
      {description || <p>{description}</p>}
      <table>
        <tbody>
          {questions.map((question, nr) => (
            <Question
              key={nr}
              {...question}
              others={questions}
              members={members}
              translate={translate}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

Page.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object)
};

Page.defaultProps = {
  questions: []
};

// =========================================

export function Survey({
  title,
  name,
  description,
  pages,
  teams,
  response,
  translate,
  _id
}) {
  const [tab, setTab] = React.useState(0);

  if (!title) {
    return "Geen gegevens beschikbaar";
  }

  return (
    <>
      <h2>
        {title} - {teams.map(team => team.name).join(", ")}
      </h2>
      {response && (
        <>
          <p>
            {response}x {translate("FILLED IN")}
          </p>
          <div style={{ textAlign: "right" }}>
            <Link to={`/print/results/${teams[0]._id}/${_id}`}>
              <Icon icon="print" /> {translate("PRINT")}
            </Link>
          </div>
        </>
      )}
      <Tabs id="survey" selectedTabId={tab} onChange={setTab}>
        {pages.map((page, nr) => (
          <Tab
            id={nr}
            key={nr}
            title={page.name}
            panel={<Page {...page} translate={translate} />}
          />
        ))}
      </Tabs>
    </>
  );
}

Survey.defaultProps = {
  teams: []
};

// =========================================

export function Print({ report, translate }) {
  React.useEffect(() => {
    if (!window.top.Cypress) {
      // when testing
      window.print();
    }
  }, []);

  return (
    <>
      <h1>{report.title}</h1>
      <p>
        Versie: {report.version}
        <br />
        Team: {report.teams[0].name}
      </p>
      {report.pages.map((page, nr) => (
        <Page key={nr} {...page} translate={translate} />
      ))}
    </>
  );
}

// =========================================
