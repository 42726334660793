import package_data from "../package.json";

const title = "TeamConnect";

var config = {
  title,
  version: package_data.version,
  development: process.env.NODE_ENV === "development",
  production: process.env.NODE_ENV === "production",
  //testing: api: process.env.REACT_APP_API || "https://teamconnecttest.herokuapp.com/"
  //production: 
  api: process.env.REACT_APP_API || "https://teamconnectglobal.herokuapp.com"
};

export default config;
