import { useState, useEffect } from "react";

export function useCyclicState(values, first) {
  const [value, setValue] = useState(first || values[0]);

  function forward() {
    var index = values.indexOf(value);
    index = (index + 1) % values.length;
    const newValue = values[index];
    setValue(newValue);
    return newValue;
  }

  return [value, forward];
}

export function useEffectAsync_BROKEN(asyncFn, onSuccess) {
  // hook to perform an async effect
  useEffect(() => {
    let isMounted = true;
    asyncFn().then(response => {
      if (isMounted) onSuccess(response);
    });
    return () => {
      isMounted = false;
    };
  }, [asyncFn, onSuccess]);
}
