export class I18n {
  constructor(languages, language) {
    this.languages = languages.languages;
    this.entries = languages.entries;
    this.language = language;
  }

  translate(key, language) {
    language = language || this.language;
    const entry = this.entries[key];
    if (entry) {
      return (
        entry[language] || entry["en"] || `UNDEFINED: ${key} (${language})`
      );
    }
    return `UNDEFINED: ${key} (${language})`;
  }
}
