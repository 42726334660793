import React from "react";

import { api } from "../../feathers";
import { ControlledForm } from "../Form";

export function ActorsForm({ _id, actors, translate }) {
  return (
    <>
      {actors.length > 0 && (
        <div style={{ marginLeft: "20pt" }}>
          <ControlledForm
            name="edit_team"
            title={translate("EDIT ACTOR")}
            fields={[
              {
                type: "select",
                name: "actor",
                label: translate("NAME"),
                translate,
                items: actors,
                itemRenderer: actor => actor.label,
                value: actors[0]
              },
              {
                type: "text",
                name: "name",
                label: translate("NEW NAME"),
                value: ""
              },
              {
                type: "select",
                name: "color",
                label: translate("COLOR"),
                translate,
                items: [
                  "tomato",
                  "orange",
                  "yellow",
                  "chartreuse",
                  "green",
                  "deepskyblue",
                  "royalblue",
                  "orchid",
                  "white",
                  "dimgrey"
                ],
                itemRenderer: color => (
                  <div style={{ background: color, textAlign: "center" }}>
                    {translate(color.toUpperCase())}
                  </div>
                )
              }
            ]}
            submit={translate("CONFIRM")}
            put={data => {
              if (!data.actor) {
                window.alert(translate("SELECT ACTOR FIRST"));
                return;
              }
              const query = {};
              if (data.name) {
                query["actors.$.label"] = data.name;
              }
              if (data.color) {
                query["actors.$.color"] = data.color;
              }
              api.save(
                "live",
                {
                  $set: query
                },
                _id,
                {
                  query: { "actors._id": data.actor._id }
                }
              );
            }}
            buttons={[
              {
                text: translate("REMOVE"),
                onClick: ({ actor }) =>
                  api.save(
                    "live",
                    { $pull: { actors: { _id: actor._id } } },
                    _id
                  )
              }
            ]}
          />
        </div>
      )}
      <div style={{ marginLeft: "20pt" }}>
        <ControlledForm
          name="add_actor"
          title={translate("ADD ACTOR")}
          fields={[
            {
              type: "text",
              name: "name",
              label: translate("NAME"),
              value: ""
            }
          ]}
          submit={translate("CONFIRM")}
          put={data =>
            api.save("live", { $push: { actors: { label: data.name } } }, _id)
          }
        />
      </div>
    </>
  );
}

export function LiveForm(props) {
  const { _id, name, team, settings, translate } = props;

  return (
    <div style={{ display: "flex" }}>
      <ControlledForm
        name="live"
        title={translate("EDIT LIVE")}
        collection="live"
        id={_id}
        fields={[
          {
            type: "text",
            name: "name",
            label: translate("NAME"),
            value: name || ""
          },
          {
            type: "boolean",
            name: "blocked",
            label: translate("COACHES ONLY"),
            value: settings.blocked || false
          },
          {
            type: "boolean",
            name: "dashboard",
            label: translate("ALLOW DASHBOARD"),
            value: settings.dashboard || false
          },
          {
            type: "boolean",
            name: "zoom",
            label: translate("ZOOM"),
            value: settings.zoom || false
          }
        ]}
        submit={translate("CONFIRM")}
        buttons={[
          {
            text: translate("REMOVE"),
            onClick: () => {
              api.remove("live", _id).then(() =>
                api.save("teams", { $unset: { liveId: 1 } }, team, {
                  silent: true
                })
              );
            }
          }
        ]}
        put={vals => {
          vals = {
            name: vals.name,
            settings: { blocked: vals.blocked, dashboard: vals.dashboard, zoom: vals.zoom }
          };
          api.save("live", vals, _id);
        }}
      />
      <ActorsForm {...props} />
    </div>
  );
}

LiveForm.defaultProps = {
  buttons: [],
  settings: {}
};
