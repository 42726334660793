import React from "react";
import PropTypes from "prop-types";

import { Arc, Group, Text } from "react-konva";

import Error from "../Errors";
import Seat from "./Seat";

export default function Domain({
  name,
  seats,
  selection,
  setSelection,
  value,
  onChange,
  ...props
}) {
  if (!seats.length) return <Error>Domain {name} has no seats.</Error>;

  // set rotation and arc for seats
  const { x, y, rotation, angle } = props;
  const part = angle / seats.length;
  seats = seats.map((seat, nr) => ({
    ...props,
    name: seat,
    innerRadius: x * 0.7,
    outerRadius: x * 0.9,
    rotation: rotation + angle - (nr + 1) * part,
    angle: part - 5,
    stroke: "black",
    strokeWidth: 2
  }));

  const domainProps = {
    ...props,
    angle: angle - 5,
    innerRadius: x * 0.6,
    outerRadius: x * 0.65,
    fill: "grey",
    stroke: "black",
    strokeWidth: 4
  };

  const direction = ((90 + rotation + angle / 2) * Math.PI) / 180;
  const textProps = {
    ...props,
    fontStyle: "bold",
    fontSize: 20,
    text: name,
    rotation: 0,
    x: x - 25 + (x * Math.sin(direction)) / 2,
    y: y - (y * Math.cos(direction)) / 2
  };

  return (
    <Group>
      <Arc {...domainProps} />
      {seats.map((seat, nr) => (
        <Seat
          key={nr}
          {...seat}
          value={
            value && value.seats
              ? value.seats.find(st => st.seat === seat.name)
              : undefined
          }
          onChange={onChange ? color => onChange(seat.name, color) : undefined}
        />
      ))}
      <Text {...textProps} />
    </Group>
  );
}

Domain.propTypes = {
  name: PropTypes.string.isRequired,
  seats: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  angle: PropTypes.number.isRequired
};

Domain.defaultProps = {
  value: { seats: [] }
};
