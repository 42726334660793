import React from "react";

import { Link } from "react-router-dom";
import { ButtonGroup, Icon, Navbar } from "@blueprintjs/core";

import { matchPermissions, isElevated } from "../utils";

export function Logo({ style, ...props }) {
  return (
    <div style={style}>
      <a
        href="https://www.teamconnect.global/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/teamconnect.jpg"
          alt="Logo TeamConnect"
          height="70pt"
          {...props}
        />
      </a>
    </div>
  );
}

export default function({ user, translate }) {
  function NavLink({ name, icon, permissions }) {
    const permission = matchPermissions(user, permissions);
    return permission ? (
      <Link className="bp3-button" to={"/" + name.toLowerCase()}>
        {icon ? <Icon icon={icon} /> : translate(name)}
      </Link>
    ) : null;
  }

  return (
    <Navbar fixedToTop={true}>
      <Navbar.Group className="bp3-navbar-group bp3-align-left">
        <ButtonGroup>
          <NavLink name="" icon="home" />
          <NavLink
            name="USERS"
            permissions={{ users: "write", surveys: "write" }}
          />
          <NavLink
            name="SUBSCRIPTIONS"
            permissions={{ subscriptions: "write" }}
          />
          <NavLink name="TEAMS" permissions={{ users: "write" }} />
          <NavLink name="TEXTS" permissions={{ texts: "write" }} />
          <NavLink name="RESULTS" permissions={{ surveys: "read" }} />
          {isElevated(user) && (
            <NavLink name="SANDBOX" permissions={{ surveys: "write" }} />
          )}
          <NavLink name="LIVE" permissions={{ live: "read" }} />
          <NavLink name="MANUAL" permissions={{ live: "write" }} />
        </ButtonGroup>
      </Navbar.Group>
      <Navbar.Group className="bp3-navbar-group bp3-align-right">
        <Navbar.Heading>
          <Logo height="50pt" style={{ marginTop: "4pt" }} />
        </Navbar.Heading>
        {/* <Navbar.Divider /> */}
        {/* <Languages language={language} setLanguage={setLanguage} /> */}
        <Navbar.Divider />
        <NavLink icon="log-out" name="SIGNOFF" />
      </Navbar.Group>
    </Navbar>
  );
}
