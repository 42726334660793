import React from "react";
import PropTypes from "prop-types";

import { InputGroup } from "@blueprintjs/core";
import { api } from "../feathers";
import { Button, ButtonGroup, Intent } from "@blueprintjs/core";

const getState = ({ page, size, total }) => {
  const totalPages = Math.ceil(total / size);
  while (page < 0) {
    page += totalPages;
  }

  // create an array of pages to ng-repeat in the pager control
  let startPage, endPage;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (page <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (page + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = page - 5;
      endPage = page + 4;
    }
  }
  const pages = [...Array(endPage + 1 - startPage).keys()].map(
    i => startPage + i
  );

  // Too large or small page
  if (page > totalPages) page = totalPages;
  if (page < 1) page = 1;

  return {
    page,
    size,
    total,
    pages,
    totalPages
  };
};

export const Pagination = ({ page, total, size, onPageChange }) => {
  const state = getState({ page, total, size });
  if (state.totalPages === 1) return null;

  return (
    <div>
      <br />
      <ButtonGroup>
        <Button
          disabled={state.page === 1}
          onClick={() => {
            onPageChange(1);
          }}
        >
          &lt;&lt;&lt;
        </Button>
        {state.pages.map(page => (
          <Button
            key={page}
            intent={state.page === page ? Intent.PRIMARY : Intent.NONE}
            disabled={state.page === page}
            onClick={() => {
              onPageChange(page);
            }}
          >
            {page}
          </Button>
        ))}
        <Button
          disabled={state.page === state.totalPages}
          onClick={() => {
            onPageChange(state.totalPages);
          }}
        >
          &gt;&gt;&gt;
        </Button>
      </ButtonGroup>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  onPageChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  page: 1,
  size: 0,
  total: 0
};

// ==============================

export function PaginatedCollection({
  service,
  table,
  tableOptions,
  filter,
  query
}) {
  const Table = table;
  const [collection, setCollection] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = React.useState({});

  function loadCollection(value) {
    if (page < 1) {
      setPage(1);
    }

    const q = {
      ...query, // get a fresh copy
      $skip: (page - 1) * PAGE_SIZE,
      $limit: PAGE_SIZE
    };

    if (value && typeof value === "string") {
      filter(q, value);
    }

    api.load(service, q).then(resp => {
      if (resp) {
        setPagination({ page, total: resp.total, size: resp.limit });
        setCollection(resp.data);
      }
    });
  }

  React.useEffect(() => {
    loadCollection();

    api.setListener(service, "created", loadCollection);
    api.setListener(service, "patched", loadCollection);

    return () => {
      api.removeListener(service, "created", loadCollection);
      api.removeListener(service, "patched", loadCollection);
    };
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <Table collection={collection} setPage={setPage} {...tableOptions} />
      <div style={{ display: "flex" }}>
        <Pagination {...pagination} onPageChange={setPage} />
        <InputGroup
          placeholder="Filter"
          style={{ marginTop: "18pt" }}
          onChange={e => {
            loadCollection(e.target.value);
          }}
        />
      </div>
    </>
  );
}

PaginatedCollection.defaultProps = {
  preprocess: docs => docs,
  query: {}
};

// ==============================
