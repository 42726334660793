import React from "react";

import { Cell, EditableCell, Column, Table } from "@blueprintjs/table";
import "@blueprintjs/table/lib/css/table.css";
import { SelectField, DateField } from "./Form";

import { api } from "../feathers";

// ========================================

export function SubscriptionTable({ collection, setPage, translate }) {
  return (
    <Table numRows={collection.length}>
      <Column
        name={translate("EMAIL")}
        cellRenderer={row => (
          <EditableCell
            onConfirm={value =>
              api.save("subscriptions", { email: value }, collection[row]._id)
            }
            value={collection[row].email}
          />
        )}
      />
      <Column
        name={translate("TYPE")}
        cellRenderer={row => (
          <Cell>
            <SelectField
              key={collection[row]._id}
              items={["surveys", "live"]}
              value={collection[row].type}
              translate={translate}
              onChange={data =>
                api.save(
                  "subscriptions",
                  { type: data.target.value },
                  collection[row]._id
                )
              }
            />
          </Cell>
        )}
      />
      <Column
        name={translate("EXPIRY DATE")}
        cellRenderer={row => (
          <Cell>
            <DateField
              key={collection[row]._id}
              value={
                collection[row].expires && new Date(collection[row].expires)
              }
              onChange={data => {
                api.save(
                  "subscriptions",
                  { expires: data.target.value },
                  collection[row]._id
                );
              }}
            />
          </Cell>
        )}
      />
    </Table>
  );
}

// ========================================
