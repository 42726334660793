export const radius = 40;

export const settingProps = {
  width: 700,
  height: 600
};

settingProps.fullWidth = settingProps.width + 150;
settingProps.scale = Math.min(1, window.innerWidth / settingProps.fullWidth);

const shapeProps = {
  stroke: "black",
  strokeWidth: 5,
  shadowColor: "black",
  shadowBlur: 10,
  shadowOpacity: 0.6
};

export const circleProps = {
  ...shapeProps,
  radius
};

export const wedgeProps = {
  ...shapeProps,
  angle: 60,
  radius: radius + 10,
  fill: "white",
  strokeWidth: 3
};

export const textProps = {
  offsetX: radius / 2,
  offsetY: 3,
  align: "center",
  verticalAlign: "middle",
  fontStyle: "bold"
};
