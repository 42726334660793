import React from "react";
import { useParams, Link } from "react-router-dom";

import { api } from "../feathers";
import { Logo } from "../components/Navigation";
import { ControlledForm, SelectField } from "../components/Form";
import Setting from "../components/Live/Setting";
import { LiveForm, ActorsForm } from "../components/Live/Form";
import { Languages } from "../components/Languages";
import { hasPermission, isElevated } from "../utils";

// ============================

function LiveRespondent({ user, translate, ...props }) {
  // show current live setting of user's team
  const [team, setTeam] = React.useState(user && user.teams[0]); // user team
  const [current, setCurrent] = React.useState(); // current live setting for team
  const { teamId } = useParams(); // team id from params
  const settings = (current && current.settings) || {};

  React.useEffect(() => {
    const updateCurrent = data => {
      // if a live setting of the current team is updated, update current
      if (data.team === team._id) {
        setCurrent(data);
      }
    };

    api.setListener("live", "created", updateCurrent);
    api.setListener("live", "patched", updateCurrent);

    api.setListener("teams", "patched", data => {
      if (!team) {
        console.warn("Team not set: ", data);
        return;
      }
      // update live setting when team changes
      if (data._id === team._id) {
        if (data.liveId) {
          team.liveId = data.liveId;
          api.get("live", data.liveId).then(resp => setCurrent(resp));
        } else {
          setCurrent(null);
        }
      }
    });

    return () => {
      // clean up
      api.removeListener("live", "created");
      api.removeListener("live", "patched");
      api.removeListener("teams", "patched");
    };
  }, [team]);

  React.useEffect(() => {
    // get live setting from teamId (from params)
    if (teamId) {
      api.get("teams", teamId).then(resp => setTeam(resp));
    }
  }, [teamId]);

  React.useEffect(() => {
    // get live setting from team (from dropdown)
    if (team && team.liveId) {
      api
        .get("live", team.liveId, null, () =>
          console.info("Team live opstelling verouderd?")
        )
        .then(resp => setCurrent(resp));
    } else {
      setCurrent(null);
    }
  }, [team]);

  return current ? (
    <>
      {user ? (
        ""
      ) : (
        <div style={{ float: "right", textAlign: "right" }}>
          <Logo height="70pt" />
          <br />
          <Languages {...props} />
        </div>
      )}
      <Setting user={user} {...current} translate={translate} />
      {!settings.blocked && settings.dashboard && (
        <div style={{ display: "flex" }}>
          <ActorsForm {...current} translate={translate} />
        </div>
      )}
    </>
  ) : (
    <p style={{ marginTop: "20pt" }}>{translate("NO CONSTELLATION YET")}</p>
  );
}

// ============================

function LiveHost({ user, translate, language }) {
  const [liveList, setLiveList] = React.useState({}); // live settings for selected team
  const [teams, setTeams] = React.useState({}); // accessible teams with access to live

  // set teams once
  React.useEffect(() => {
    api
      .load("teams", { allowLive: true, $limit: -1, $sort: "name" })
      .then(items => {
        if (items) {
          setTeams({ items, value: items[0] });
        }
      });
  }, []);

  // handle team changes
  React.useEffect(() => {
    if (teams.value) {
      const team = teams.value;

      // when a new live setting is created for current team, add it to the list and make it current
      api.setListener("live", "created", value => {
        if (value.team === team._id) {
          setLiveList(ll => {
            return { value, items: [...ll.items, value] };
          });
        }
      });

      // if a live setting of the current team is updated, update current and list
      api.setListener("live", "patched", value => {
        if (value.team === team._id) {
          setLiveList(ll => {
            if (ll.value._id === value._id) {
              const items = [...ll.items];
              const index = items.findIndex(s => s._id === value._id);
              items[index] = value;
              return { value, items };
            } else {
              return ll;
            }
          });
        }
      });

      // if a live setting of the current team is removed, update list
      api.setListener("live", "removed", data => {
        if (data.team === team._id) {
          setLiveList(ll => {
            const items = ll.items.filter(s => s._id !== data._id);
            return {
              value:
                ll.value._id === data._id
                  ? items.length > 0
                    ? items[0]
                    : null
                  : ll.value,
              items
            };
          });
        }
      });

      function findItem(items, itemId) {
        return items.find(l => l._id === itemId);
      }

      // if live setting of current team changes, update current
      api.setListener("teams", "patched", data => {
        if (data._id === team._id) {
          setLiveList(ll => {
            const value = findItem(ll.items, data.liveId);
            team.liveId = data.liveId;
            return { ...ll, value };
          });
        }
      });

      // set live list and current from team
      api.load("live", { team: team._id }).then(({ data }) => {
        setLiveList(() => {
          return {
            value: findItem(data, team.liveId),
            items: data
          };
        });
      });
    }

    return () => {
      // clean up
      api.removeListener("live", "created");
      api.removeListener("live", "patched");
      api.removeListener("live", "removed");
      api.removeListener("teams", "patched");
    };
  }, [teams.value]);

  function getLink() {
    const path = `/live/${teams.value._id}/${language}`;
    const link = (
      <Link
        to={path}
        ref={node => {
          if (node) {
            node.innerHTML = node.href;
          }
        }}
      >
        dummy
      </Link>
    );
    return link;
  }

  return (
    <>
      <h2>{translate("LIVE CONSTELLATION")}</h2>
      <div style={{ display: "flex" }}>
        {teams.items && teams.items.length > 0 ? (
          <div>
            <h3>{translate("SELECT TEAM")}</h3>
            <SelectField
              {...teams}
              translate={translate}
              itemRenderer={item => item.name}
              itemPredicate={(query, item) =>
                item.name && item.name.indexOf(query) >= 0
              }
              onChange={data =>
                setTeams({ ...teams, value: data.target.value })
              }
            />
          </div>
        ) : (
          translate("NO LIVE TEAM")
        )}
        {liveList.items && liveList.items.length > 0 && (
          <div style={{ marginLeft: "20pt" }}>
            <h3>{translate("SELECT LIVE")}</h3>
            <SelectField
              key={liveList.value && liveList.value._id}
              {...liveList}
              translate={translate}
              itemRenderer={item => item.name}
              onChange={data =>
                api.save(
                  "teams",
                  { liveId: data.target.value._id },
                  teams.value._id
                )
              }
            />
          </div>
        )}
        {teams.value && (
          <div style={{ marginLeft: "20pt" }}>
            <ControlledForm
              title={translate("ADD LIVE")}
              fields={[
                {
                  label: translate("NAME"),
                  name: "name",
                  type: "text",
                  value: ""
                }
              ]}
              put={({ name }) => {
                if (name)
                  api
                    .save("live", { name, team: teams.value._id })
                    .then(live => {
                      api.save("teams", { liveId: live._id }, teams.value._id);
                    });
              }}
              submit={translate("ADD")}
            />
          </div>
        )}
      </div>
      {teams.value && teams.value.public && (
        <p>
          {translate("UNIQUE LINK")}: {getLink()}
        </p>
      )}
      {liveList.value ? (
        <div key={liveList.value._id}>
          <Setting
            key={liveList.value._id}
            user={user}
            {...liveList.value}
            translate={translate}
          />
          <LiveForm {...liveList.value} translate={translate} />
        </div>
      ) : (
        <p style={{ marginTop: "20pt" }}>{translate("NO CONSTELLATION YET")}</p>
      )}
    </>
  );
}

// ============================

export function Live(props) {
  const { user } = props;
  const { language } = useParams();

  React.useEffect(() => {
    if (language && props.setLanguage) {
      props.setLanguage(language);
    }
    // eslint-disable-next-line
  }, []);

  if (user && hasPermission(user, "live", "read")) {
    if (isElevated(user, ["coach"])) {
      return <LiveHost {...props} />;
    }
  }
  return <LiveRespondent {...props} />;
}

// ============================
