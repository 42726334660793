import React from "react";

import { SmartToaster, toast } from "react-smart-toaster";
import Konva from "konva";

import "./App.css";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import { PrivateRoutes, PublicRoutes } from "./Routing";
import config from "./config";
import { api } from "./feathers";
import { I18n } from "./i18n";

Konva.pixelRatio = 1; // https://github.com/konvajs/konva/issues/608

export default function App() {
  const [user, setUser] = React.useState(null);
  const [settings, setSettings] = React.useState(null);
  const [language, setLanguage] = React.useState("nl");
  const props = { title: config.title, setUser };

  // Note: config is client-side, settings are server-side

  React.useEffect(() => {
    api.load("settings").then(resp => {
      setSettings(resp);
    });
  }, []);

  const i18n = settings && new I18n(settings.languages, language);
  if (i18n) {
    api.translate = key => i18n.translate(key);
  }

  return (
    <div className="container bp3-running-text bp3-text-large">
      <SmartToaster
        store={toast}
        lightBackground={true}
        position={"top_right"}
      />
      {settings ? (
        user ? (
          <PrivateRoutes
            {...props}
            user={user.user}
            config={config}
            settings={{
              ...settings,
              language,
              setLanguage,
              translate: key => i18n.translate(key)
            }}
          />
        ) : (
          <PublicRoutes
            {...props}
            settings={{
              ...settings,
              language,
              setLanguage,
              translate: key => i18n.translate(key)
            }}
          />
        )
      ) : (
        "..."
      )}
    </div>
  );
}
