import React from "react";

import {
  HashRouter as Router,
  Route as BaseRoute,
  Switch,
  Redirect
} from "react-router-dom";

import { matchPermissions, isElevated } from "./utils";

import Navigation from "./components/Navigation";
import { Text } from "./components/Content";

import Home from "./pages/Home";
import Login, { Signoff } from "./pages/Auth";
import { Live } from "./pages/Live";
import { Demo } from "./pages/Setting";
import { Teams, Members, TeamSurveys } from "./pages/Team";
import { Users } from "./pages/User";
import { Subscriptions } from "./pages/Subscription";
import { Reports, Report } from "./pages/Report";
import { Survey, Sandbox } from "./pages/Survey";
import { Texts, Editor } from "./pages/Text";

// import survey from "./pages/survey.json";

function Footer({
  email,
  role,
  language,
  config,
  permissions,
  settings,
  translate
}) {
  var footer = `Server v${settings.version} / client v${config.version}.`;
  if (email) {
    footer = `${footer} ${translate(
      "LOGGED IN AS"
    )} ${role} ${email} (${language || "nl"}) ${JSON.stringify(permissions)}`;
  }
  return <footer>{footer}</footer>;
}

export function PrivateRoutes({ config, settings, user, ...props }) {
  const [showMessages, setShowMessages] = React.useState(true);

  function Route({ children, permissions, ...routeProps }) {
    var granted = matchPermissions(user, permissions);
    if (!granted)
      console.log(
        `U heeft geen toegang tot pagina "${routeProps.path}": naar startpagina`
      );
    var messages =
      showMessages && isElevated(user, ["coach"]) ? config.messages : [];
    if (messages && messages.length > 0) {
      if (messages.length === 1) {
        messages = messages[0];
      } else {
        messages = (
          <ol>
            {messages.map(msg => (
              <li key={msg}>{msg}</li>
            ))}
          </ol>
        );
      }
      messages = (
        <div
          style={{
            float: "right",
            maxWidth: "250pt",
            margin: "10pt",
            padding: "5pt",
            borderRadius: "4pt",
            backgroundColor: "gold"
          }}
        >
          {messages}
          <button onClick={() => setShowMessages(false)}>Begrepen</button>
        </div>
      );
    }
    return (
      <BaseRoute {...routeProps}>
        {granted ? (
          <>
            {messages}
            <Navigation {...props} user={user} {...settings} />
            {children}
            <Footer
              {...user}
              config={config}
              settings={settings}
              translate={settings.translate}
            />
          </>
        ) : (
          <Redirect to="/" />
        )}
      </BaseRoute>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/sandbox" permissions={{ surveys: "write" }}>
          <Sandbox
            locale={settings.language}
            translate={settings.translate}
            allowUpload={true}
          />
        </Route>
        <Route path="/users" permissions={{ users: "write" }}>
          <Users
            user={user}
            translate={settings.translate}
            languages={settings.languages.languages}
          />
        </Route>
        <Route path="/subscriptions" permissions={{ subscriptions: "write" }}>
          <Subscriptions translate={settings.translate} />
        </Route>
        <Route path="/teams" permissions={{ users: "write" }}>
          <Teams user={user} translate={settings.translate} />
        </Route>
        <Route path="/members/:teamId" permissions={{ users: "write" }}>
          <Members translate={settings.translate} />
        </Route>
        <Route path="/texts" permissions={{ texts: "write" }}>
          <Texts
            user={user}
            translate={settings.translate}
            languages={settings.languages.languages}
          />
        </Route>
        <Route path="/text/:textId" permissions={{ texts: "write" }}>
          <Editor translate={settings.translate} />
        </Route>
        <Route path="/questions/:teamId" permissions={{ surveys: "read" }}>
          <TeamSurveys translate={settings.translate} />
        </Route>
        <Route
          path="/results/:teamId/:surveyId"
          permissions={{ surveys: "read" }}
        >
          <Report user={user} translate={settings.translate} />
        </Route>
        <Route path="/results" permissions={{ surveys: "read" }}>
          <Reports translate={settings.translate} />
        </Route>
        <Route path="/fillin/:teamId/:surveyId">
          <Survey user={user} translate={settings.translate} />
        </Route>
        <BaseRoute
          path="/print/results/:teamId/:surveyId"
          permissions={{ surveys: "read" }}
        >
          <Report user={user} translate={settings.translate} format="pdf" />
        </BaseRoute>
        <BaseRoute
          path="/print/questions/:teamId/:surveyId"
          permissions={{ surveys: "read" }}
        >
          <Survey user={user} translate={settings.translate} format="pdf" />
        </BaseRoute>
        <Route path="/live" permissions={{ live: "read" }}>
          <Live user={user} {...settings} />
        </Route>
        <Route path="/manual" permissions={{ live: "write" }}>
          <Text
            name="ManualLive"
            locale={settings.language}
            translate={settings.translate}
          />
        </Route>
        <Route path="/signoff">
          <Signoff setUser={props.setUser} />
        </Route>
        <Route path="/">
          <Home {...user} {...settings} />
        </Route>
      </Switch>
    </Router>
  );
}

export function PublicRoutes(props) {
  return (
    <Router>
      <Switch>
        <BaseRoute path="/live/:teamId/:language?">
          <Live {...props.settings} />
        </BaseRoute>
        {/* <BaseRoute path="/survey"> */}
        {/*   <Logo style={{ float: "right" }} /> */}
        {/*   <Sandbox */}
        {/*     locale="en" */}
        {/*     setLanguage={props.settings.setLanguage} */}
        {/*     translate={props.settings.translate} */}
        {/*     survey={survey} */}
        {/*   /> */}
        {/* </BaseRoute> */}
        <BaseRoute path="/demo">
          <Demo translate={props.settings.translate} />
        </BaseRoute>
        <BaseRoute path="/">
          <Login {...props} translate={props.settings.translate} />
        </BaseRoute>
      </Switch>
    </Router>
  );
}
