import React from "react";
import PropTypes from "prop-types";

// https://github.com/plotly/react-plotly.js/issues/135#issuecomment-501398125
// import Plot from 'react-plotly.js';
import Plotly from "plotly.js/dist/plotly-basic";

import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

const bgColor = "#efffff";

export function labelTicks(labels) {
  const [min, max] = [0, 100];
  const ticktext = labels && labels.length ? labels : ["niet", "helemaal"];
  const interval = (max - min) / (ticktext.length - 1);
  var tickvals = ticktext.map((val, nr) => min + nr * interval);
  return { ticktext, tickvals };
}

const plotProps = {
  layout: {
    // paper_bgcolor: bgColor,
    // paper_bgcolor:
    //   "linear-gradient(45deg, rgba(148,227,159,1) 0%, rgba(121,9,9,0.7) 100%)",
    plot_bgcolor: bgColor,
    width: 550,
    height: 350,
    xaxis: { range: [0, 100] },
    yaxis: { range: [0, 100] },
    zaxis: { range: [0, 100] },
    margin: { l: 125, r: 90, b: 30, t: 30 },
    padding: { l: 30, r: 30, b: 60, t: 30 }
  },
  config: {
    displayModeBar: false
  }
};

// =========================================

export function Scatter({ data, layout, xLabels, yLabels }) {
  const props = {
    ...plotProps,
    data,
    layout: {
      ...plotProps.layout,
      showlegend: data.length > 1,
      legend: {
        orientation: "v",
        x: 0,
        y: -0.3
      },
      ...layout
    }
  };

  props.layout.xaxis.fixedrange = true;
  props.layout.yaxis.fixedrange = true;

  return <Plot {...props} />;
}

// =========================================

export function Histogram({ name, data, labels }) {
  // can't get Plotly histogram/density plot to work, so fake it
  const ticks = labelTicks(labels);
  var size = Math.max(5, ticks.ticktext.length * 2 - 1);
  const arr = new Array(size).fill({});
  const length = 100 / size;
  const range = length / 2;
  const blocks = arr.map((val, nr) => {
    return {
      value: range + nr * length,
      count: 0
    };
  });

  data.forEach(value => {
    const block = blocks.find(block => Math.abs(block.value - value) <= range);
    if (block) {
      block.count += 1;
    } else {
      console.warning("value out of range: ", value, blocks);
    }
  });

  const y = blocks.map(block => block.count);
  const maxY = Math.max(...y);
  const plot = {
    data: [
      {
        x: blocks.map(block => block.value),
        y,
        mode: "lines",
        line: { shape: "spline", width: 4 },
        hoverinfo: "none"
      }
    ],
    layout: {
      ...plotProps.layout,
      xaxis: { fixedrange: true, range: [0, 100], ...ticks },
      yaxis: { fixedrange: true, range: [0, maxY + 0.5] },
      height: 200
    },
    config: { ...plotProps.config }
  };

  return <Plot {...plot} />;
}

// =========================================

export function BarChart(props) {
  const plot = {
    data: [
      {
        ...props,
        type: "bar",
        hoverinfo: "none"
      }
    ],
    layout: {
      ...plotProps.layout,
      xaxis: { fixedrange: true },
      yaxis: { fixedrange: true },
      height: 250
    },
    config: { ...plotProps.config }
  };

  return <Plot {...plot} />;
}

BarChart.propTypes = {
  x: PropTypes.arrayOf(PropTypes.string).isRequired,
  y: PropTypes.arrayOf(PropTypes.number).isRequired
};

// =========================================

export default Plot;

// =========================================
