import React from "react";

import { api } from "../feathers";
import { isElevated, PIN } from "../utils";

import { ControlledForm } from "../components/Form";
import { UserTable } from "../components/User";
import { PaginatedCollection } from "../components/Pagination";

// ========================================

export function Users({ user, translate, languages }) {
  const fields = [
    { name: "name", label: translate("NAME"), type: "text", value: "" },
    { name: "email", label: translate("EMAIL"), type: "text", value: "" },
    {
      name: "language",
      label: translate("LANGUAGE"),
      type: "select",
      translate,
      items: languages,
      value: user.language
    }
  ];
  if (isElevated(user)) {
    fields.push({
      name: "role",
      label: translate("ROLE"),
      type: "select",
      items: ["coach", "respondent"],
      translate,
      value: "respondent"
    });
  }

  return (
    <>
      <h1>{translate("USERS")}</h1>
      <PaginatedCollection
        service="users"
        query={{ $sort: { createdAt: -1 } }}
        table={UserTable}
        tableOptions={{ user, translate, languages }}
        filter={(query, value) => (query.email = { $regex: value })}
      />

      <ControlledForm
        title={translate("ADD USER")}
        fields={fields}
        put={data => {
          data.email = data.email.trim();
          if (
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              data.email
            )
          ) {
            data.password = PIN();
            api
              .save("users", { ...data, coachId: user._id })
              .then(user =>
                window.alert(
                  `${translate("NEW PASSWORD FOR")} ${user.email}: ${
                    data.password
                  }`
                )
              );
          } else {
            window.alert(translate("EMAIL REQUIRED"));
          }
        }}
        submit={translate("ADD")}
      />
    </>
  );
}

// ========================================
