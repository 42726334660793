import React from "react";
import PropTypes from "prop-types";

import CircleComponent from "../Circle/Circle";

function mergeSeat(seat, seatAnswers) {
  const answers = seatAnswers.reduce((obj, answer) => {
    if (obj[answer.color]) {
      obj[answer.color] += 1;
    } else {
      obj[answer.color] = 1;
    }
    return obj;
  }, {});

  return Object.keys(answers).length ? { seat, color: answers } : null;
}

function mergeDomain(domain, answers) {
  return domain.seats.map(seat => {
    const seatAnswers = answers.map(answer => {
      return answer.seats.find(seatAnswer => {
        return seatAnswer.seat === seat;
      });
    });
    return mergeSeat(
      seat,
      seatAnswers.filter(e => e)
    );
  });
}

function mergeCircle(domains, answers) {
  if (answers.length) {
    return domains.map(domain => {
      const domainAnswers = answers.map(answer =>
        answer.value.find(domainAnswer => domainAnswer.domain === domain.name)
      );

      return {
        domain: domain.name,
        seats: mergeDomain(
          domain,
          domainAnswers.filter(e => e)
        ).filter(e => e)
      };
    });
  }
  return [];
}

export default function Circle(props) {
  const answers = mergeCircle(props.domains, props.answers);

  if (answers.length) {
    return <CircleComponent {...props} value={answers} />;
  }
  return <p>{props.translate("NO ANSWERS")}</p>;
}

Circle.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object)
};

Circle.defaultProps = {
  answers: []
};
