import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Button, Checkbox, Collapse } from "@blueprintjs/core";
import { Cell, EditableCell, Column, Table } from "@blueprintjs/table";
// import { DateInput } from "@blueprintjs/datetime";
import "@blueprintjs/table/lib/css/table.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { ControlledForm, SelectField } from "./Form";
import { fullSurveyName } from "./Survey";

import { api } from "../feathers";
import { isElevated, hasPermission, PIN } from "../utils";

// ========================================

async function getUsers(emails, memberEmails, translate) {
  const newUsers = [];
  emails = emails
    .split("\n")
    .slice(0, 25)
    .map(email => email.trim())
    .filter(email => email !== "")
    .filter(email => !memberEmails.includes(email));
  var users = await Promise.all(
    emails.map(email =>
      api.load("users", { email }).then(async resp => {
        if (resp.total === 0) {
          const user = {
            email,
            password: PIN(),
            role: "respondent"
          };
          newUsers.push(user);
          return await api.save("users", user);
        } else {
          return resp.data[0];
        }
      })
    )
  );

  if (newUsers.length > 0) {
    window.alert(
      translate("NEW USERS") +
      ": " +
      newUsers.map(user => `${user.email}: ${user.password}`).join(" / ")
    );
  }

  return users;
}

// ========================================

export function MemberTable({ team, translate }) {
  const { members, candidates } = team;
  const [isOpen, setIsOpen] = React.useState(false);

  function fancyEmail(member) {
    var email = member.email;
    if (member.name) email = `${member.name} <${member.email}>`;
    return email;
  }

  return (
    <>
      <Table numRows={members.length}>
        <Column
          name={translate("NAME")}
          cellRenderer={row => <Cell>{members[row].name}</Cell>}
        />
        <Column
          name={translate("EMAIL")}
          cellRenderer={row => <Cell>{members[row].email}</Cell>}
        />
        <Column
          name={translate("ACTIONS")}
          cellRenderer={row => (
            <Cell>
              <Button
                onClick={() =>
                  api.save(
                    "teams",
                    { $pull: { members: members[row]._id } },
                    team._id
                  )
                }
                icon="cross"
                small
              />
            </Cell>
          )}
        />
      </Table>
      <Button
        onClick={e => setIsOpen(!isOpen)}
        icon={isOpen ? "caret-up" : "caret-down"}
        minimal
      >
        {isOpen ? translate("HIDE") : translate("SHOW")} mail list
      </Button>
      <Collapse isOpen={isOpen}>
        <div style={{ background: "#eee", padding: "3pt" }}>
          {team.members.map(member => fancyEmail(member)).join(", ")}
        </div>
      </Collapse>
      <div style={{ display: "flex" }}>
        <ControlledForm
          title={translate("ADD MEMBERS")}
          fields={[
            {
              name: "emails",
              label: translate("EMAILS"),
              type: "textarea",
              cols: 25,
              rows: 15,
              placeholder: translate("FILL IN EMAILS"),
              value: ""
            }
          ]}
          put={async data => {
            const users = await getUsers(
              data.emails,
              team.members.map(member => member.email),
              translate
            );
            if (users.length > 0) {
              api.save(
                "teams",
                {
                  $push: { members: { $each: users.map(user => user._id) } }
                },
                team._id
              );
            }
          }}
          submit={translate("ADD")}
        />

        {candidates && candidates.length > 0 && (
          <div style={{ marginLeft: "20pt" }}>
            <ControlledForm
              title={translate("SELECT MEMBER")}
              fields={[
                {
                  name: "user",
                  label: translate("USER"),
                  translate,
                  type: "select",
                  items: candidates,
                  itemRenderer: item => item.email,
                  itemPredicate: (query, item) =>
                    item.email && item.email.indexOf(query) >= 0,
                  value: candidates[0]
                }
              ]}
              put={data =>
                api.save(
                  "teams",
                  { $push: { members: data.user._id } },
                  team._id
                )
              }
              submit={translate("ADD")}
            />
          </div>
        )}
      </div>
    </>
  );
}

// ========================================

export function SurveyTable({ team, surveys, translate }) {
  return (
    <>
      <Table numRows={team.surveys.length}>
        <Column
          name={translate("TITLE")}
          cellRenderer={row => <Cell>{team.surveys[row].title}</Cell>}
        />
        <Column
          name={translate("VERSION")}
          cellRenderer={row => <Cell>{team.surveys[row].version}</Cell>}
        />
        <Column
          name={translate("ACTIONS")}
          cellRenderer={row => (
            <Cell>
              <Button
                onClick={() =>
                  api.save(
                    "teams",
                    { $pull: { surveys: team.surveys[row]._id } },
                    team._id
                  )
                }
                icon="cross"
                small
              />
            </Cell>
          )}
        />
      </Table>
      <ControlledForm
        title={translate("ADD SURVEY")}
        fields={[
          {
            name: "survey",
            label: translate("SURVEY"),
            type: "select",
            translate,
            items: surveys,
            itemRenderer: item => `${item.title} - ${item.version}`,
            value: surveys[0]
          }
        ]}
        put={data =>
          api.save("teams", { $push: { surveys: data.survey._id } }, team._id)
        }
        submit={translate("ADD")}
      />
    </>
  );
}

// ========================================

export function TeamTable({ collection, user, surveys, translate }) {
  function textRenderer(field) {
    return row =>
      collection[row].name === "Ontwikkelteam" ? (
        <Cell>{collection[row][field]}</Cell>
      ) : (
        <EditableCell
          onConfirm={value =>
            api.save("teams", { [field]: value }, collection[row]._id)
          }
          value={collection[row][field]}
        />
      );
  }

  function boolRenderer(field) {
    return row => (
      <Cell>
        <Checkbox
          checked={collection[row][field]}
          onChange={e =>
            api.save(
              "teams",
              { [field]: e.target.checked },
              collection[row]._id
            )
          }
        />
      </Cell>
    );
  }

  function listRenderer(field, path) {
    return row => (
      <Cell>
        <Link to={`${path}/${collection[row]._id}`}>
          {collection[row][field].length}
        </Link>
      </Cell>
    );
  }

  var columns = [
    { name: translate("NAME"), cellRenderer: textRenderer("name") }
  ];

  if (hasPermission(user, "surveys", "read")) {
    columns = columns.concat([
      {
        name: translate("MEMBERS"),
        cellRenderer: listRenderer("members", "members")
      },
      {
        name: translate("SURVEY"),
        cellRenderer: row => (
          <Cell>
            {false && isElevated(user) ? (
              <SelectField
                key={collection[row]._id}
                items={surveys}
                itemRenderer={fullSurveyName}
                value={collection[row].surveys[0]}
                translate={translate}
                onChange={data =>
                  api.save(
                    "teams",
                    { surveys: [data.target.value._id] },
                    collection[row]._id
                  )
                }
              />
            ) : (
              collection[row].survey
            )}
          </Cell>
        )
      },
      { name: translate("REPORT"), cellRenderer: boolRenderer("allowAccess") }
    ]);
  }

  if (hasPermission(user, "live", "read")) {
    columns = columns.concat([
      { name: translate("LIVE"), cellRenderer: boolRenderer("allowLive") },
      { name: translate("PUBLIC"), cellRenderer: boolRenderer("public") }
    ]);
  }

  if (hasPermission(user, "users", "write")) {
    columns.push({
      name: translate("ACTIONS"),
      cellRenderer: row => (
        <Cell>
          <Button
            onClick={() => {
              api.hide("teams", collection[row]._id);
            }}
            name="delete"
            icon="cross"
            small
          />
        </Cell>
      )
    });
  }

  if (isElevated(user)) {
    columns.splice(1, 0, {
      name: translate("COACH"),
      cellRenderer: row => <Cell>{collection[row].coach}</Cell>
    });
  }

  var columnWidths = new Array(columns.length).fill(100);
  columnWidths[0] = 225;
  if (isElevated(user)) {
    columnWidths[3] = 175;
  } else {
    columnWidths[2] = 175;
  }

  return (
    <>
      <Table
        numRows={collection.length}
        // eslint-disable-next-line
        columnWidths={columnWidths}
      >
        {columns.map(column => (
          <Column key={column.name} {...column} />
        ))}
      </Table>
    </>
  );
}

TeamTable.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object).isRequired
};

// ========================================
