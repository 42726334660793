import React from "react";
import { useHistory } from "react-router-dom";

import { Logo } from "../components/Navigation";
import { ControlledForm } from "../components/Form";
import { Text } from "../components/Content";
import { Languages } from "../components/Languages";
import { api } from "../feathers";

function LoginForm({ setUser, naam, wachtwoord, settings, ...props }) {
  const fields = [
    {
      type: "text",
      name: "email",
      label: settings.translate("NAME:"),
      value: naam || ""
    },
    {
      type: "password",
      name: "password",
      label: settings.translate("PASSWORD:"),
      value: wachtwoord || ""
    }
  ];

  return (
    <ControlledForm
      {...props}
      title={settings.translate("SIGNIN")}
      put={values =>
        api.authenticate(values).then(login => {
          setUser(login);
          if (login) settings.setLanguage(login.user.language || "nl");
        })
      }
      fields={fields}
      submit={settings.translate("SIGNIN")}
    />
  );
}

export function Signoff({ setUser }) {
  const history = useHistory();

  history.goBack();
  api.logout();
  setUser(null);
  return null;
}

export default function({ title, translate, ...props }) {
  return (
    <div>
      <Logo />
      <div style={{ float: "right" }}>
        <Languages {...props.settings} />
      </div>
      <LoginForm {...props} />
      <div style={{ marginTop: "30pt" }} />
      <Text
        name="Landing"
        locale={props.settings.language}
        translate={translate}
      />
    </div>
  );
}
