import React from "react";

import PropTypes from "prop-types";
import { Button, Collapse, FileInput, Tab, Tabs } from "@blueprintjs/core";
import SyntaxHighlighter from "react-syntax-highlighter";

export default function FileLoader({
  content,
  log,
  onChange,
  parse,
  tabs,
  stringify,
  translate
}) {
  const [fileName, setFileName] = React.useState();
  const [tab, setTab] = React.useState("src");
  const [isOpen, setIsOpen] = React.useState(false);

  async function getFile({ target }) {
    const file = target.files[0];
    setFileName(file.name);
    const text = await file.text();
    onChange(text);
  }

  return (
    <div>
      <Button
        onClick={e => setIsOpen(!isOpen)}
        icon={isOpen ? "caret-up" : "caret-down"}
        minimal
      >
        {isOpen ? translate("HIDE") : translate("SHOW")} {translate("DATA")}
      </Button>

      <Collapse isOpen={isOpen}>
        <Tabs id="demo" selectedTabId={tab} onChange={setTab}>
          <Tab
            id="src"
            title={translate("SOURCE")}
            panel={
              <SyntaxHighlighter language="json" showLineNumbers>
                {content}
              </SyntaxHighlighter>
            }
          />
          <Tab
            id="file"
            title={translate("UPLOAD")}
            panel={<FileInput text={fileName} onInputChange={getFile} />}
          />
          {tabs.map((tab, nr) => (
            <Tab key={nr} id={tab.title} title={tab.title} panel={tab.panel} />
          ))}
        </Tabs>
      </Collapse>
    </div>
  );
}

FileLoader.propTypes = {
  content: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object)
};

FileLoader.defaultProps = {
  content: "",
  tabs: []
};
