import io from "socket.io-client";
import feathers from "@feathersjs/client";
import { toast } from "react-smart-toaster";

import config from "./config";

function handleError(msg, err) {
  toast.error(msg);
  console.error(err || msg);
}

class API {
  constructor(url) {
    console.log("API: ", url);
    this.url = url;
    this._init();
    this.translate = key => key;
  }

  _init() {
    this.client = feathers();

    const socket = io(this.url);
    this.client
      .configure(feathers.socketio(socket, { timeout: 10000 }))
      .configure(
        feathers.authentication({
          storage: window.localStorage
        })
      );
  }

  service(name) {
    return typeof name === "string" ? this.client.service(name) : name;
  }

  authenticate({ email, password }) {
    return this.client
      .authenticate({ strategy: "local", email, password })
          .catch(err => handleError(this.translate("LOGIN FAILED") + ": " + err.message, err));
  }

  logout() {
    this.client.logout();
    // The above turns out to be insufficient, so let's forget everything!
    delete this.client;
    this._init();
  }

  save(service, data, id, params) {
    var silent = false;
    if (params && params.silent) {
      silent = params.silent;
      delete params.silent;
    }
    service = this.service(service);
    if (data._id) {
      // extract id when embedded in data
      data = { ...data }; // make copy so we can remove id
      id = data._id;
      delete data._id;
    }

    const promise = id
      ? service.patch(id, data, params)
      : service.create(data, params);
    return promise
      .then(result => {
          silent || toast.success(this.translate("DATA SAVED"));
        return result;
      })
      .catch(err => handleError(this.translate("SAVE FAILED") + ": " + err));
  }

  get(service, id, query, customErrorHandler) {
    return this.service(service)
      .get(id, query && { query })
      .catch(err =>
        customErrorHandler
          ? customErrorHandler(err)
          : handleError(this.translate("QUERY FAILED")+": " + err)
      );
  }

  remove(service, id) {
    if (window.confirm(this.translate("SAVE FAILED"))) {
      return this.service(service)
        .remove(id)
        .then(result => {
            toast.success(this.translate("DATA DELETED"));
          return result;
        })
            .catch(err => handleError(this.translate("DELETE FAILED") + ": " + err));
    }
    return null;
  }

  hide(service, id, extra = {}) {
      if (window.confirm(this.translate("ARE YOU SURE"))) {
      return this.save(service, { hidden: true, ...extra }, id).catch(err =>
        handleError(this.translate("DELETE FAILED") + ": " + err)
      );
    }
    return null;
  }

  load(service, query) {
    return this.service(service)
      .find(query && { query })
      .catch(err => handleError(this.translate("QUERY FAILED") + ": " + err));
  }

  /* make sure there is at most one listener for a particular event */
  setListener(service, event, callback) {
    service = this.service(service);
    this.removeListener(service, event);
    if (callback) {
      service.on(event, callback);
    } else {
      console.warn("Deprecated: setListener without callback");
    }
    return service.listeners(event);
  }

  removeListener(service, event, callback) {
    service = this.service(service);
    if (callback) {
      service
        .listeners(event)
        .filter(listener => listener.name === callback.name)
        .forEach(listener => service.removeListener(event, listener));
    } else {
      service.removeAllListeners(event);
    }
    return service.listeners(event);
  }

  /* make sure every listener for a particular event is added at most once
  based on callback.name
  NB: this does not work with anonymous callback functions */
  // addListener(service, event, callback) {
  //   console.log(
  //     "add listener: ",
  //     service,
  //     event,
  //     callback ? callback.name : "anon"
  //   );
  //   service = this.service(service);
  //   this.removeListener(service, event, callback);
  //   service.on(event, callback);
  //   return service.listeners(event);
  // }
}

// singleton
export const api = new API(config.api);
