import React from "react";
import PropTypes from "prop-types";

import { Arc, Group, Text } from "react-konva";

import { GBR, neutral } from "../colors";
import { useCyclicState } from "../../hooks";

export default function Seat({
  name,
  selection,
  setSelection,
  value,
  onChange,
  ...props
}) {
  const guiProps = {};
  const palette = [neutral].concat(GBR);
  const [color, forwardColor] = useCyclicState(
    palette,
    value ? value.color : neutral
  );
  var shape;

  if (typeof color == "string") {
    if (onChange) {
      guiProps.onClick = () => onChange(forwardColor());
      guiProps.onTouchStart = guiProps.onClick;
    }
    shape = <Arc {...props} fill={color} />;
  } else {
    var { innerRadius, outerRadius } = props;
    const colors = value.color;
    const values = Object.keys(colors);

    // divide space over colors
    const thickness =
      (outerRadius - innerRadius) /
      values.reduce((sum, val) => sum + value.color[val], 0);
    shape = palette
      .filter(col => colors[col])
      .map((fill, nr) => {
        const outerRadius = innerRadius + value.color[fill] * thickness;
        const arc = { fill, innerRadius, outerRadius };
        innerRadius = outerRadius;

        return <Arc key={nr} {...props} {...arc} />;
      });
  }

  const { x, y, rotation, angle } = props;
  const direction = ((90 + rotation + angle / 2) * Math.PI) / 180;
  const correction = 0.85;
  const textProps = {
    text: name,
    rotation: 0,
    x: x - 25 + x * Math.sin(direction) * correction,
    y: y - y * Math.cos(direction) * correction
  };

  return (
    <Group {...guiProps}>
      {shape}
      <Text {...textProps} />
    </Group>
  );
}

Seat.propTypes = {
  name: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  value: PropTypes.object,
  rotation: PropTypes.number.isRequired,
  angle: PropTypes.number.isRequired
};

Seat.defaultProps = {
  value: { color: neutral }
};
