export function isElevated({ role }, extra) {
  return ["onderzoeker", "beheerder"].concat(extra).includes(role);
}

// mirrored in server/src/services/common.js
export function hasPermission(user, service, request) {
  const levels = { read: 1, write: 2, delete: 3, all: 4 };
  const granted = user.permissions[service];

  return granted && levels[granted] >= levels[request];
}

export function matchPermissions(user, permissions) {
  var granted = permissions === undefined;

  if (!granted) {
    granted = true;
    for (var service in permissions) {
      granted = granted && hasPermission(user, service, permissions[service]);
    }
  }
  return granted;
}

export const findDuplicates = arr => {
  let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
};

export function PIN() {
  //LeonSwinkels - create longer PIN
  const pin = "000000000" + Math.floor(Math.random() * 10000 * 10000);
  return pin.substr(pin.length - 9);
}
