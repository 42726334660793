import React from "react";

import { api } from "../feathers";
import { ControlledForm } from "../components/Form";
import { SubscriptionTable } from "../components/Subscription";
import { PaginatedCollection } from "../components/Pagination";

// ========================================

export function Subscriptions({ translate }) {
  const [coaches, setCoaches] = React.useState([]);
  const types = ["surveys", "live"];

  React.useEffect(() => {
    api
      .load("users", { role: "coach", $limit: -1, $sort: { email: 1 } })
      .then(resp => {
        setCoaches(resp);
      });
    return; // don't return promise
  }, []);

  return (
    <>
      <h1>{translate("SUBSCRIPTIONS")}</h1>
      <PaginatedCollection
        service="subscriptions"
        query={{ $sort: { expires: -1 } }}
        table={SubscriptionTable}
        tableOptions={{ translate }}
        filter={(query, value) => {
          if (typeof value === "string") {
            query.email = { $regex: value };
          }
        }}
      />

      {coaches && (
        <ControlledForm
          title={translate("ADD SUBSCRIPTION")}
          fields={[
            {
              name: "user",
              translate,
              label: translate("USER"),
              type: "select",
              value: coaches[0],
              items: coaches,
              itemRenderer: user => user.email,
              itemPredicate: (query, item) =>
                item.email && item.email.indexOf(query) >= 0
            },
            {
              name: "type",
              translate,
              label: translate("TYPE"),
              type: "select",
              value: types[0],
              items: types
            },
            {
              name: "expires",
              label: translate("EXPIRY DATE"),
              type: "date",
              value: null
            }
          ]}
          put={data => {
            data.userId = data.user._id;
            delete data.user;
            api.save("subscriptions", data);
          }}
          submit={translate("ADD")}
        />
      )}
    </>
  );
}

// ========================================
