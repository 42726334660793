import React from "react";
import PropTypes from "prop-types";

import SettingWidget from "./Setting";
import CircleWidget from "../Circle";

// =========================================

function Question({ children, answer, ...props }) {
  const { title, type } = props;
  if (type === "html") {
    return (
      <p
        style={{ paddingTop: "30pt" }}
        dangerouslySetInnerHTML={{ __html: props.html }}
      />
    );
  }

  if (answer.meta) {
    answer = <input type="text" value={answer.meta} readOnly={true} />;
  } else if (!answer.value) {
    answer = "-----";
  } else {
    switch (type) {
      case "number":
        if (props.labels.length === 0) {
          props.labels = ["niet", "helemaal"];
        }
        answer = (
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: "10pt", marginRight: "10pt" }}>
              {props.labels[0]}
            </div>
            <input
              type="range"
              style={{ width: "300pt" }}
              min={props.min}
              max={props.max}
              value={answer.value}
              readOnly={true}
            />
            <div style={{ fontSize: "10pt", marginLeft: "10pt" }}>
              {props.labels[props.labels.length - 1]}
            </div>
          </div>
        );
        break;
      case "setting":
        answer = <SettingWidget {...props} value={answer.value} />;
        break;
      case "circle":
        answer = <CircleWidget {...props} value={answer.value} />;
        break;
      default:
        answer = answer.value;
    }
  }

  let label = children || title;
  return (
    <div className="question">
      {label || "ERROR"}: <div style={{ margin: "10pt" }}>{answer}</div>
    </div>
  );
}

Question.propTypes = {
  type: PropTypes.oneOf([
    "html",
    "boolean",
    "number",
    "select",
    "text",
    "setting",
    "circle"
  ]),
  name: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  answer: PropTypes.object
};

Question.defaultProps = {
  type: "text",
  answer: { value: undefined, meta: undefined }
};

// =========================================

function Page({ name, title, description, questions, answers }) {
  return (
    <div style={{ pageBreakBefore: "always" }}>
      <h2>
        {name} - {title}
      </h2>
      {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
      {questions.map((question, nr) => (
        <Question
          key={nr}
          {...question}
          answer={answers.questions.find(
            answer => answer.question === question.name
          )}
        />
      ))}
    </div>
  );
}

Page.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object),
  answers: PropTypes.object
};

Page.defaultProps = {
  questions: [],
  answers: { questions: [] }
};

// =========================================

function Survey({ survey, user }) {
  return (
    <>
      <h1>{survey.title}</h1>
      <p>
        Versie: {survey.version}
        <br />
        Respondent: {user.email}
      </p>
      {survey.pages.map((page, nr) => (
        <Page
          key={nr}
          {...page}
          answers={survey.answers.pages.find(
            answers => answers.page === page.name
          )}
        />
      ))}
    </>
  );
}

// =========================================

export function SurveyPrint(props) {
  React.useEffect(() => {
    if (!window.top.Cypress) {
      // skip when testing

      // useEffect does not wait for page to render, before opening print dialog
      // KLUDGE: so add a small delay
      setTimeout(() => window.print(), 500);
    }
  }, []);

  return <Survey {...props} />;
}

// =========================================
