import React from "react";
import PropTypes from "prop-types";

import { radius, settingProps } from "../Setting/ShapeProps";

import Setting from "../Setting/Setting";
import { distance, savePosition } from "../dnd";

/** shows an interactive setting */
export default function SettingWidget(props) {
  const { width, onChange } = props;
  const [actors, setActors] = React.useState(
    (props.value && props.value.actors) ||
      props.actors.map((actor, nr) => ({
        label: actor.label,
        battery: actor.battery,
        x: actor.x || width + radius + 25,
        y: actor.y || 10 + radius + nr * (15 + 2 * radius)
      }))
  );
  const [targets, setTargets] = React.useState(
    (props.value && props.value.targets) || props.targets
  );
  const debug = false;
  const dataProps = {
    targets,
    updateTarget,
    actors,
    updateActor
  };
  const dndProps = {
    onDrop: (e, nr) =>
      updateActor(nr, savePosition(e, settingProps, radius / 2))
  };

  function updateActor(nr, value) {
    const newActors = [...actors];
    newActors[nr] = { ...newActors[nr], ...value };
    setActors(newActors);
    onChange({ actors: newActors, targets });
  }

  function updateTarget(nr, value) {
    // for batteries
    const newTargets = [...targets];
    newTargets[nr].value = value;
    setTargets(newTargets);
    onChange({ targets: newTargets, actors });
  }

  return (
    <>
      <Setting {...props} data={dataProps} dnd={dndProps} />
      {debug && (
        <>
          <h3>Testgegevens:</h3>
          <table>
            <thead>
              <tr>
                <th>actor</th>
                <th>gegevens</th>
                <th>afstanden</th>
              </tr>
            </thead>
            <tbody>
              {actors.map((actor, nr) => (
                <tr key={nr}>
                  <td>{actors[nr].label}</td>
                  <td>
                    {Object.keys(actor).map(key => (
                      <li key={key}>
                        {key}: {Math.round(actor[key])}
                      </li>
                    ))}
                  </td>
                  <td>
                    {targets.map((target, nr) => (
                      <li key={nr}>
                        {target.label}:{" "}
                        {Math.round(
                          distance(actor.x, actor.y, target.x, target.y)
                        )}
                      </li>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

SettingWidget.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.object,
  onChange: PropTypes.func
};

SettingWidget.defaultProps = {
  width: settingProps.width,
  value: {},
  onChange: () => {}
};
